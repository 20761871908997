import React, { useState, useEffect } from "react";
import List from "../components/List";
let candidates = [
  {
    ID: "CAND001",
    Name: "John Doe",
    "Profile Pic": "JohnDoe.png",
    "Area of Research": "Physics",
    Experience: "5 years",
    Gender: "M",
    Bookmark: "",
  },
  {
    ID: "CAND002",
    Name: "Jane Doe",
    "Profile Pic": "JohnDoe.png",
    "Area of Research": "Chemistry",
    Experience: "3 years",
    Gender: "F",
    Bookmark: "",
  },
];

const ViewCandidateProfiles = () => {
  const [candidateTableHeader, setCandidateTableHeader] = useState([]);
  const [candidateTableData, setCandidateTableData] = useState([]);

  useEffect(() => {
    getCandidatesData();
  }, []);

  function getCandidatesData() {
    setCandidateTableHeader(Object.keys(candidates[0]));
    setCandidateTableData(candidates);
  }

  return (
    <main>
      <div>
        <h2 style={{ textAlign: "center" }}>Candidate Profiles</h2>
        <div class="main-content">
          <div class="dashboard-section">
            <div class="data-table">
              <div class="search-container">
                <label for="search-input">Search:</label>
                <input
                  id="search-input"
                  placeholder="Enter search keyword"
                  type="text"
                />
              </div>
              <List
                tableHeader={candidateTableHeader}
                tableData={candidateTableData}
                updateData={getCandidatesData}
                // role={"test"}
              />
              <button class="filter-button">Filter</button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ViewCandidateProfiles;
