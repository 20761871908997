import React from "react";
import "../CSS/academiaDashboard.css";

const Number_of_JP = () => {
  return (
    <>
      <main>
        <div>
          <h2 style={{textAlign: "center"}}>Job Postings</h2>
          <div class="main-content">
            <div class="dashboard-section">
              <div class="data-table">
                <div class="search-container">
                  <label for="search-input">Search:</label>
                  <input
                    id="search-input"
                    placeholder="Enter search keyword"
                    type="text"
                  />
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Job ID</th>
                      <th>Position</th>
                      <th>Description</th>
                      <th>Location</th>
                      <th>Salary</th>
                      <th>Req Experience(yrs)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>JOB001</td>
                      <td>Software Engineer</td>
                      <td>Develop and maintain software applications</td>
                      <td>New York, USA</td>
                      <td>$80,000</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>JOB002</td>
                      <td>Data Scientist</td>
                      <td>Analyze and interpret complex data sets</td>
                      <td>San Francisco, USA</td>
                      <td>$100,000</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>JOB003</td>
                      <td>Marketing Manager</td>
                      <td>Develop and implement marketing strategies</td>
                      <td>London, UK</td>
                      <td>$90,000</td>
                      <td>4</td>
                    </tr>
                  </tbody>
                </table>
                <p>Total Job Postings: 3</p>
                <button class="filter-button">Filter</button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Number_of_JP;
