import React from "react";
import "../CSS/academiaDashboard.css";

const Academia2 = () => {
  return (
    <>
      <main>
        <h2>Welcome, [Academic Name]</h2>
        <div class="boxcard">
          <h2>Details of the Academia</h2>
          <div class="details">
            <p>
              <strong>Institution Name:</strong> URM Academia Institution
            </p>
            <p>
              <strong>Institution Code:</strong> URM123
            </p>
            <p>
              <strong>Address:</strong> 123, Main Street, City
            </p>
            <p>
              <strong>Phone Number:</strong> (123) 456-7890
            </p>
          </div>
        </div>
        <h2>Dashboard</h2>
        <section id="dashboard">
          <div class="dashboard-options">
            <div class="option">
              <a href="manageProfileAca">
                <i class="fa-solid fa-user-circle"></i>
                <p>Create and Manage Profile</p>
              </a>
            </div>
            <div class="option">
              <a href="aboutAcademiaInfo">
                <i class="fa-solid fa-info"></i>
                <p>About our Institution</p>
              </a>
            </div>
            <div class="option">
              <a href="viewFaculty">
                <i class="fa-solid fa-chalkboard-user"></i>
                <p>View Faculty</p>
              </a>
            </div>
            <div class="option">
              <a href="numberOfJP">
                <i class="fa-solid fa-clipboard-list"></i>
                <p>Number of Job Postings</p>
              </a>
            </div>
            <div class="option">
              <a href="statusOfApplicationAca">
                <i class="fa-solid fa-tasks"></i>
                <p>Status of Applications Received</p>
              </a>
            </div>
            <div class="option">
              <a href="viewCandidateProfiles">
                <i class="fas fa-users"></i>
                <p>View Candidate Profiles</p>
              </a>
            </div>
            {/* <!-- Location and Interested candidates info --> */}
            <div class="option">
              <a href="updateJobPreferences">
                <i class="fa-solid fa-gear"></i>
                <p>Update Preferences</p>
              </a>
            </div>
          </div>
        </section>
        <div class="button-container">
          <a href="addNewJob">
            <button class="main-button">Add a New Job Posting</button>
          </a>
          <a href="bookmarkedProfiles">
            <button class="main-button">View Bookmarked Profiles</button>
          </a>
        </div>
      </main>
    </>
  );
};

export default Academia2;
