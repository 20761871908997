import React from "react";
import { Route, Link, Routes, useLocation } from "react-router-dom";
import "../CSS/sidebar.css";

const Header = () => {
  const location = useLocation();
  let notificationPages = [
    "candidate_dashboard",
    "academia2",
    "recruiterDashboard",
    "dei_dashboard",
    "aboutAcademiaInfo",
    "manageProfileAca",
    "manageProfile",
    "manageProfileDEI",
    "manageProfileRec",
    "viewFaculty",
    "numberOfJP",
    "statusOfApplicationAca",
    "viewCandidateProfiles",
    "updateJobPreferences",
    "addNewJob",
    "bookmarkedProfiles",
    "uploadFiles",
    "updateAcademicRec",
    "candidatePreferences",
    "updateDEIInfo",
    "recruiterDashboard",
    "updateAgency"
  ];
  const { pathname } = location;
  let path = pathname.slice(1);

  return (
    <header>
      <div class="logo">
        <img
          alt="Company Logo"
          src="http://rxp6700.uta.cloud/Phase1/images/Logo.png"
        />
      </div>
      <nav>
        <ul>
          <li>
            <a class="btn" href="http://sxt4029.uta.cloud/">
              <i class="fa fa-home"></i> Home
            </a>
          </li>
          <li>
            <a class="btn" href="about">
              <i class="fa-solid fa-circle-info"></i> About
            </a>
          </li>
          <li>
            <a class="btn" href="contactus">
              <i class="fa-solid fa-phone"></i> Contact Us
            </a>
          </li>
          <li>
            <a class="btn" href="services">
              <i class="fa-solid fa-gears"></i> Services
            </a>
          </li>
          {notificationPages.some((str) => str.includes(path)) &&
          path.trim().length !== 0 ? (
            <>
              <li>
                <a class="btn" href="signin">
                  <i class="fa fa-sign-in"></i> Sign Out
                </a>
              </li>
            </>
          ) : (
            <>
              <li>
                <a class="btn" href="signin">
                  <i class="fa fa-sign-in"></i> Sign In
                </a>
              </li>
              <li>
                <a class="btn" href="signup_candidate">
                  <i class="fa-regular fa-user"></i> Register
                </a>
              </li>
            </>
          )}
          <li>
            <a class="btn" href="https://kxr5318.uta.cloud/blog/">
              <i class="fa-brands fa-blogger"></i> Blog
            </a>
          </li>
        </ul>
      </nav>
      {notificationPages.some((str) => str.includes(path)) &&
      path.trim().length !== 0 ? (
        <>
          <label for="sidebar-toggle" id="sidebar-icon">
            🔔
          </label>
          <input id="sidebar-toggle" type="checkbox" />
          <div id="sidebar">
            <ul>
              <li>
                <a href="#">Thanks for Signing Up!</a>
              </li>
              <li>
                <a href="#">Checkout our new schemes!</a>
              </li>
              <li>
                <a href="#">You are awesome!</a>
              </li>
            </ul>
          </div>
        </>
      ) : (
        <></>
      )}
    </header>
  );
};

export default Header;
