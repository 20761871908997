import React, { useState } from "react";
// import dataService from "../services/data.service";

function Lists(props) {
  var tableData = [];
  var tableHeader = [];
  const [editRow, setEditRow] = useState();
  const [rowData, setRowData] = useState([]);
  var roles = [];
  // if(props.editableRoles)
  //     roles = props.editableRoles
  // else
  roles = ["Admin"];

  console.log(roles, "roles");
  console.log(props.tableData);
  function getCells(row) {
    var tempData = [];
    // console.log(rowData)
    // console.log(row)
    for (const [key, val] of Object.entries(row)) {
      // editRow === row.id && key !== "id"
      //     ? tempData.push(
      //           <td key={key} data-label={key}>
      //               <input
      //                   type="text"
      //                   name={key}
      //                   value={rowData[key]}
      //                   onChange={(e) => handleChange(e)}
      //               />
      //           </td>
      //       )
      //     :
      console.log(key);
      if (key == "Bookmark") {
        tempData.push(
          <td key={key} data-label={key}>
            <button>
              <i class="fa-solid fa-bookmark"></i>
            </button>
          </td>
        );
      } else if (key == "Profile Pic") {
        tempData.push(
          <td key={key} data-label={key}>
            <img src="profile1.jpg" alt="Profile Photo" width="50" />
          </td>
        );
      } else if (key == "Leave Feedback") {
        tempData.push(
          <td key={key} data-label={key}>
            <button class="feedback-button">Leave Feedback</button>
          </td>
        );}
       else {
        tempData.push(
          <td key={key} data-label={key}>
            {val}
          </td>
        );
      }
    }

    return tempData;
  }

  function handleChange(e) {
    e.preventDefault();
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  }
  function handleDelete(e, id) {
    e.preventDefault();
    // console.log(id)
    // dataService.deleteData(props.endpoint, id).then((data) => {
    //     // console.log(data)
    //     props.updateData();
    // });
  }

  function handleEdit(e, element) {
    e.preventDefault();
    if (editRow === element.id) {
      setEditRow();
      // dataService
      //     .editData(props.endpoint, element.id, rowData)
      //     .then((data) => {
      //         // console.log(data)
      //         props.updateData();
      //     });
    } else setEditRow(element.id);

    setRowData(element);
    getCells(element);
    // console.log(element.id);
  }
  console.log(roles);
  console.log(props.role);
  console.log("Role", roles.includes(props.role));
  props.tableHeader.forEach((element) => {
    tableHeader.push(<th key={element}>{element}</th>);
  });
  props.tableData.forEach((element) => {
    tableData.push(
      <tr key={element.id}>
        {getCells(element)}
        {roles.includes(props.role) && (
          <td>
            <div className="table-action-container">
              <button type="submit" onClick={(e) => handleEdit(e, element)}>
                {editRow === element.id ? (
                  <i className="fa fa-check" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                )}
              </button>
              <button
                type="submit"
                onClick={(e) => handleDelete(e, element.id)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        )}
      </tr>
    );
  });
  return (
    <table className="table">
      <thead>
        <tr>
          {tableHeader}
          {roles.includes(props.role) && <th>Action</th>}
        </tr>
      </thead>
      <tbody>{tableData}</tbody>
    </table>
  );
}

export default Lists;
