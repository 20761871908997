import React, {useState} from "react";
import "../CSS/deiDashboard.css";

const DEI_dashboard = () => {


  return (
    <main>
        <h2>Welcome, [DEI officer Name]</h2>
        <div class="container">
          <div class="left-section">
            <div class="boxcard">
              <h2>Details of the DEI Institution</h2>
              <div class="details">
                <p>
                  <strong>Institution Name:</strong> DEI Institution
                </p>
                <p>
                  <strong>Institution Code:</strong> DEI123
                </p>
                <p>
                  <strong>Address:</strong> 123, Main Street, City
                </p>
                <p>
                  <strong>Phone Number:</strong> (123) 456-7890
                </p>
                <p>
                  Our goal is to create an inclusive and equitable<p></p>{" "}
                  platform that fosters diversity
                </p>
                <a href="updateDEIInfo">
                  <button>Update DEI details</button>
                </a>
              </div>
            </div>
            <div class="boxcard">
              <h2>Matched profiles</h2>
              <p>Number of job postings that meet DEI criteria: 10</p>
              <p>Number of URM candidates interested in those postings: 25</p>
            </div>
            <div class="boxcard">
              <h2>Events at DEI Office</h2>
              <p>
                <a href="event1">
                  Workshop on Diversity and Inclusion! NEW!!
                </a>
              </p>
              <p>
                <a href="event2">
                  Panel Discussion on Equity in Education! NEW!!
                </a>
              </p>
              <p>
                <a href="event3">Cultural Fair Celebrating Diversity!</a>
              </p>
            </div>
            <div class="boxcard">
              <div>
                <a href="manageProfileDEI">
                  <button class="update-profile-button">Update Profile</button>
                </a>
                <a href="viewCandidateProfiles">
                  <button class="candidates-profile-button">
                    Candidates Profile
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div class="right-section">
            <div class="boxcard">
              <h2>Review &amp; Edit Job Postings</h2>
              <table class="job-postings-table">
                <thead>
                  <tr>
                    <th>JOB ID</th>
                    <th>Job Name</th>
                    <th>Location</th>
                    <th>Salary</th>
                    <th>Experience</th>
                    <th>Race</th>
                    <th>Gender</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>123</td>
                    <td>Data Analyst</td>
                    <td>New York</td>
                    <td>$60,000</td>
                    <td>2 years</td>
                    <td>Asian</td>
                    <td>Male</td>
                    <td>
                      <button class="approve-button">
                        <i class="fa-solid fa-thumbs-up"></i>
                      </button>
                      <button class="disapprove-button">
                        <i class="fa-solid fa-thumbs-down"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>456</td>
                    <td>Software Engineer</td>
                    <td>San Francisco</td>
                    <td>$80,000</td>
                    <td>3 years</td>
                    <td>African American</td>
                    <td>Female</td>
                    <td>
                      <button class="approve-button">
                        <i class="fa-solid fa-thumbs-up"></i>
                      </button>
                      <button class="disapprove-button">
                        <i class="fa-solid fa-thumbs-down"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>789</td>
                    <td>Marketing Specialist</td>
                    <td>Chicago</td>
                    <td>$65,000</td>
                    <td>2 years</td>
                    <td>Hispanic</td>
                    <td>Male</td>
                    <td>
                      <button class="approve-button">
                        <i class="fa-solid fa-thumbs-up"></i>
                      </button>
                      <button class="disapprove-button">
                        <i class="fa-solid fa-thumbs-down"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>987</td>
                    <td>Financial Analyst</td>
                    <td>Los Angeles</td>
                    <td>$70,000</td>
                    <td>4 years</td>
                    <td>Caucasian</td>
                    <td>Non-Binary</td>
                    <td>
                      <button class="approve-button">
                        <i class="fa-solid fa-thumbs-up"></i>
                      </button>
                      <button class="disapprove-button">
                        <i class="fa-solid fa-thumbs-down"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <a href="updateJobPreferences">
                <button class="update-preferences-button">
                  Update Job ID Preferences
                </button>
              </a>
            </div>
            <div class="boxcard">
              <h2>Chat Requests Received</h2>
              <table class="chat-requests-table">
                <thead>
                  <tr>
                    <th>Chat ID</th>
                    <th>Name</th>
                    <th>Subject</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>John Doe</td>
                    <td>Job Inquiry</td>
                    <td>2023-07-19 12:30 PM</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Jane Smith</td>
                    <td>Application Status</td>
                    <td>2023-07-19 01:15 PM</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <a href="#">
              <button class="update-preferences-button">
                Download Reports
              </button>
            </a>
          </div>
        </div>
      </main>
  );
};

export default DEI_dashboard;
