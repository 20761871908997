import React from "react";
import "../CSS/recruiterDashboard.css";

const RecruiterDashboard = () => {
  return (
    <>
      <main>
        <h2>Welcome, [Recruiter Name]</h2>
        <div class="container">
          <div class="left-section">
            <div class="boxcard">
              <h2>Agency Details</h2>
              <div class="details">
                <p>
                  <strong>Agency Name:</strong>123 Agency
                </p>
                <p>
                  <strong>Agency Code:</strong> AA123
                </p>
                <p>
                  <strong>Address:</strong> 123, Main Street, City
                </p>
                <p>
                  <strong>Phone Number:</strong> (123) 456-7890
                </p>
                <a href="updateAgency">
                  <button class="update_button">Update Agency details</button>
                </a>
              </div>
            </div>
            <div class="boxcard">
              <h2>Our Clients</h2>
              <table class="matching-candidates-table">
                <thead>
                  <tr>
                    <th>Client ID</th>
                    <th>Client Name</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>101</td>
                    <td>John Doe</td>
                    <td>New York</td>
                  </tr>
                  <tr>
                    <td>202</td>
                    <td>Jane Smith</td>
                    <td>San Francisco</td>
                  </tr>
                  <tr>
                    <td>303</td>
                    <td>Michael Johnson</td>
                    <td>Chicago</td>
                  </tr>
                  <tr>
                    <td>404</td>
                    <td>Susan Williams</td>
                    <td>Los Angeles</td>
                  </tr>
                  <tr>
                    <td>505</td>
                    <td>Robert Lee</td>
                    <td>Houston</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="boxcard">
              <a href="manageProfileRec">
                <button class="update_button">Update Profile</button>
              </a>
              <a href="viewCandidateProfiles">
                <button class="update_button">View Candidate Profile</button>
              </a>
              <a href="addNewJob">
                <button class="update_button">Add a New Job Posting</button>
              </a>
              <a href="bookmarkedProfiles">
                <button class="update_button">View Bookmarked Profiles</button>
              </a>
            </div>
          </div>
          <div class="right-section">
            <div class="boxcard">
              <h2>Job Postings</h2>
              <table class="job-postings-table">
                <thead>
                  <tr>
                    <th>JOB ID</th>
                    <th>Job Name</th>
                    <th>Location</th>
                    <th>Salary</th>
                    <th>Experience</th>
                    <th>Race</th>
                    <th>Gender</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>123</td>
                    <td>Data Analyst</td>
                    <td>New York</td>
                    <td>$60,000</td>
                    <td>2 years</td>
                    <td>Asian</td>
                    <td>Male</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>456</td>
                    <td>Software Engineer</td>
                    <td>San Francisco</td>
                    <td>$80,000</td>
                    <td>3 years</td>
                    <td>African American</td>
                    <td>Female</td>
                    <td>Rejected</td>
                  </tr>
                  <tr>
                    <td>789</td>
                    <td>Marketing Specialist</td>
                    <td>Chicago</td>
                    <td>$65,000</td>
                    <td>2 years</td>
                    <td>Hispanic</td>
                    <td>Male</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <td>987</td>
                    <td>Financial Analyst</td>
                    <td>Los Angeles</td>
                    <td>$70,000</td>
                    <td>4 years</td>
                    <td>Caucasian</td>
                    <td>Non-Binary</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <a href="updateJobPreferences">
                <button class="update-preferences-button">
                  Update Job ID Preferences
                </button>
              </a>
            </div>
            <div class="boxcard">
              <h2>Matching Candidates</h2>
              <table class="matching-candidates-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Job ID</th>
                    <th>Candidate ID</th>
                    <th>Location</th>
                    <th>Position</th>
                    <th>Research Area</th>
                    <th>Experience</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="dummy_page.html">123</a>
                    </td>
                    <td>456</td>
                    <td>789</td>
                    <td>New York</td>
                    <td>PhD</td>
                    <td>Data Science</td>
                    <td>2 years</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="dummy_page.html">234</a>
                    </td>
                    <td>789</td>
                    <td>123</td>
                    <td>San Francisco</td>
                    <td>Postdoc</td>
                    <td>Machine Learning</td>
                    <td>3 years</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="dummy_page.html">345</a>
                    </td>
                    <td>987</td>
                    <td>654</td>
                    <td>Chicago</td>
                    <td>Faculty</td>
                    <td>Marketing</td>
                    <td>5 years</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="dummy_page.html">456</a>
                    </td>
                    <td>654</td>
                    <td>987</td>
                    <td>Los Angeles</td>
                    <td>PhD</td>
                    <td>Finance</td>
                    <td>4 years</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="dummy_page.html">567</a>
                    </td>
                    <td>123</td>
                    <td>456</td>
                    <td>Seattle</td>
                    <td>Faculty</td>
                    <td>Human Resources</td>
                    <td>6 years</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RecruiterDashboard;
