import React from "react";
import "../CSS/signup_candidate.css";

const Rough = () => {
  return (
    <>
      <main>
        <section id="aboutInstitution">
          <div class="aboutInstitution">
            <h2>About Institution</h2>
            <div class="input-container">
              <textarea
                id="aboutInstitution"
                name="aboutInstitution"
                rows="5"
              ></textarea>
              <button type="submit">Save Basic Info</button>
            </div>
          </div>
        </section>
        <br />
        <br />
        <section id="aboutInstitution">
          <div class="aboutInstitution">
            <div>
              <h2 style={{textAlign: "center"}}>Our Faculty</h2>
              <div class="main-content">
                <div class="dashboard-section">
                  <div class="data-table">
                    <div class="search-container">
                      <label for="search-input">Search:</label>
                      <input
                        id="search-input"
                        placeholder="Enter search keyword"
                        type="text"
                      />
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Profile Photo</th>
                          <th>Research Area</th>
                          <th>Experience(yrs)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Nobita Nobi</td>
                          <td class="profile-photo-column">
                            <img
                              alt="Profile Photo"
                              src="profile1.jpg"
                              width="50"
                            />
                          </td>
                          <td class="Research Area">Physics</td>
                          <td class="Experience(yrs)">5</td>
                        </tr>
                        <tr>
                          <td>Will Smith</td>
                          <td class="profile-photo-column">
                            <img
                              alt="Profile Photo"
                              src="profile2.jpg"
                              width="50"
                            />
                          </td>
                          <td class="Research Area">Mathematics</td>
                          <td class="Experience(yrs)">6</td>
                        </tr>
                        <tr>
                          <td>Chris Hemsworth</td>
                          <td class="profile-photo-column">
                            <img
                              alt="Profile Photo"
                              src="profile3.jpg"
                              width="50"
                            />
                          </td>
                          <td class="Research Area">Geology</td>
                          <td class="Experience(yrs)">6.5</td>
                        </tr>
                      </tbody>
                    </table>
                    <button class="filter-button">Filter</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <section id="interests">
          <div class="interests">
            <h2>Research Focus Area</h2>
            <div class="input-container">
              <label for="research-focus">Research Focus Area:</label>
              <input id="research-focus" name="research-focus" type="text" />
              <button type="submit">Save Research Focus</button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Rough;
