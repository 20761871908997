import React, {useState} from "react";
import "../CSS/manageProfileDEI.css";

const UpdateDEIInfo = () => {
  const [formData, setFormData] = useState({
    clientAddress: "",
    clientId: "",
    InstututionName: "",
    InstututionAddress: "",
  });

  const [submitStatus, setsubmitStatus] = useState("");

  const [registrationStatus, setRegistrationStatus] = useState(false);
  // const navigate = useNavigate();

  function handleDEIUpdate(e) {
    console.log(formData);
    if (!submitStatus) {
      e.preventDefault();
      // authService.register(formData).then((user) => {
      //   console.log(user);
      //   console.log(user.accessToken);
      //   if (user.accessToken) {
      //     navigate("/");
      //   } else {
      //     setRegistrationStatus(user);
      //   }
      // });
    }
  }

  return (
    <main>
        <section id="Update Instutution Details">
          <h2>Update Instutution Details</h2>
          <div class="manage-profile-dei-container">
            <details class="manage-profile-dei-section">
              <summary class="manage-profile-dei-section-title">DEI Info</summary>
              <div class="manage-profile-dei-section-content">
                <label for="dEI-name">DEI Name</label>
                <input
                  id="dEI-name"
                  pattern="[A-Za-z]+"
                  required=""
                  title="Please enter letters only"
                  type="text"
                />
                <label for="dEI">DEI Code</label>
                <input
                  id="dEI"
                  pattern="[A-Za-z0-9]+"
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="dEIAddress">DEI Address:</label>
                <input
                  id="dEIAddress"
                  name="dEIAddress"
                  pattern="[A-Za-z0-9 ]+"
                  required=""
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="phoneNumber">Phone Number:</label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  pattern="[0-9]+"
                  required=""
                  title="Please enter numbers only"
                  type="text"
                />
                <button type="submit">Save DEI Info</button>
              </div>
            </details>
          </div>
        </section>
      </main>
  );
};

export default UpdateDEIInfo;
