import React, {useState} from "react";
import "../CSS/chat.css";

const chat = () => {
  return (
    <main class="chat-page">
        <div class="chat-container">
          <div class="chat-header">
            <h2>Chat with Support</h2>
            <button class="close-chat-btn">Close</button>
          </div>
          <div class="chat-messages">
            {/* <!-- Chat messages will be displayed here --> */}
            <div class="message">
              <div class="sender">Support</div>
              <div class="text">Hello! How can I assist you today?</div>
            </div>
            {/* <!-- More chat messages --> */}
          </div>
          <div class="chat-input">
            <input placeholder="Type your message..." type="text" />
            <button class="send-btn">Send</button>
          </div>
        </div>
      </main>
  );
};

export default chat;
