import React from "react";
import "../CSS/uploadFiles.css";

const uploadFiles = () => {
  return (
     <main>
        <section id="uploadFiles">
          <h2>Upload Files</h2>
          <div class="flashcards-container">
            <div class="flashcard">
              <h2>Upload Resume</h2>
              <input accept=".pdf,.doc,.docx" id="resume" type="file" />
              <label for="resume">Choose File</label>
            </div>
            <div class="flashcard">
              <h2>Upload Cover Letter</h2>
              <input accept=".pdf,.doc,.docx" id="cover-letter" type="file" />
              <label for="cover-letter">Choose File</label>
            </div>
          </div>
          <div class="personal-statement">
            <h2>Personal Statement</h2>
            <div class="input-container">
              <label for="personalStatement">Personal Statement:</label>
              <textarea
                id="personalStatement"
                name="personalStatement"
                required=""
                rows="5"
              ></textarea>
            </div>
          </div>
          <button type="submit">Save</button>
        </section>
      </main>
  );
};

export default uploadFiles;
