import React, {useState} from "react";
import "../CSS/candidatePreferences.css";

const CandidatePreferences = () => {
  const [formData, setFormData] = useState({
    jobId: "",
    candidateId: "",
  });

  const [sendError, setsendError] = useState("");

  const [registrationStatus, setRegistrationStatus] = useState(false);

  return (
    <main>
        <section class="white-box applied-jobs">
          <h2>Applied Job Postings</h2>
          <table>
            <thead>
              <tr>
                <th>Job ID</th>
                <th>Job Name</th>
                <th>Location</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>123</td>
                <td>Data Analyst</td>
                <td>New York</td>
                <td>Applied</td>
              </tr>
              <tr>
                <td>456</td>
                <td>Software Engineer</td>
                <td>San Francisco</td>
                <td>Under Review</td>
              </tr>
              <tr>
                <td>789</td>
                <td>Research Scientist</td>
                <td>San Francisco</td>
                <td>Rejected</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section class="white-box update-interests">
          <h2>Update Interests</h2>
          <div class="input-container">
            <label for="areaOfResearch">Area of Research:</label>
            <input
              id="areaOfResearch"
              name="areaOfResearch"
              type="text"
              value="Machine Learning"
            />
          </div>
          <div class="input-container">
            <label for="locationPreference">Location Preference:</label>
            <input
              id="locationPreference"
              name="locationPreference"
              type="text"
              value="California"
            />
          </div>
          <button class="update-button">Update Interests</button>
        </section>
        <section class="white-box search-jobs">
          <h2>Search Job Postings</h2>
          <div class="search-container">
            <label for="search-input">Search:</label>
            <input
              id="search-input"
              placeholder="Enter search keyword"
              type="text"
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>Job ID</th>
                <th>Job Name</th>
                <th>Location</th>
                <th>Salary</th>
                <th>Experience</th>
                <th>Feedback</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>101</td>
                <td>Data Scientist</td>
                <td>New York</td>
                <td>$80,000</td>
                <td>3 years</td>
                <td>
                  <button class="feedback-button">Leave Feedback</button>
                </td>
              </tr>
              <tr>
                <td>202</td>
                <td>Software Developer</td>
                <td>San Francisco</td>
                <td>$90,000</td>
                <td>5 years</td>
                <td>
                  <button class="feedback-button">Leave Feedback</button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6">No of Job Postings: 2</td>
              </tr>
            </tfoot>
          </table>
        </section>
        <section class="white-box feedback-reviews">
          <h2>Feedback and Reviews</h2>
          <div class="review">
            <h3>User123</h3>
            <p>This platform is amazing! I found my dream job here.</p>
          </div>
          <div class="review">
            <h3>User456</h3>
            <p>The search feature is very helpful and easy to use.</p>
          </div>
          <div class="add-review">
            <h3>Add Your Review</h3>
            <input
              id="userName"
              placeholder="Your Name"
              required=""
              type="text"
            />
            <br />
            <br />
            <textarea
              id="userReview"
              placeholder="Write your review here"
              required=""
            ></textarea>
            <button class="submit-review">Submit Review</button>
          </div>
        </section>
      </main>
  );
};

export default CandidatePreferences;
