import React from "react";
// import "../CSS/viewFaculty.css";

const viewFaculty = () => {
  return (
    <>
      <div>
        <h2 style={{textAlign: "center"}}>Faculty Details</h2>
        <div class="main-content">
          <div class="dashboard-section">
            <div class="data-table">
              <div class="search-container">
                <label for="search-input">Search:</label>
                <input
                  id="search-input"
                  placeholder="Enter search keyword"
                  type="text"
                />
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Profile Photo</th>
                    <th>Research Area</th>
                    <th>Experience(yrs)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nobita Nobi</td>
                    <td class="profile-photo-column">
                      <img alt="Profile Photo" src="profile1.jpg" width="50" />
                    </td>
                    <td class="Research Area">Physics</td>
                    <td class="Experience(yrs)">5</td>
                  </tr>
                  <tr>
                    <td>Will Smith</td>
                    <td class="profile-photo-column">
                      <img alt="Profile Photo" src="profile2.jpg" width="50" />
                    </td>
                    <td class="Research Area">Mathematics</td>
                    <td class="Experience(yrs)">6</td>
                  </tr>
                  <tr>
                    <td>Chris Hemsworth</td>
                    <td class="profile-photo-column">
                      <img alt="Profile Photo" src="profile3.jpg" width="50" />
                    </td>
                    <td class="Research Area">Geology</td>
                    <td class="Experience(yrs)">6.5</td>
                  </tr>
                </tbody>
              </table>
              <button class="filter-button">Filter</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default viewFaculty;
