import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from '../components/Footer';
import Header from '../components/Header';


function Layout({roles}) {
    return(
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
}

export default Layout;