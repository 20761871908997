import React, {useState} from "react";
import "../CSS/updateAcademicRec.css";

const UpdateAcademicRec = () => {
  const [formData, setFormData] = useState({
    Education: "",
    reserachField: "",
    Experience: "",
    institution_name: "",
  });

  const [submitStatus, setsubmitStatus] = useState("");

  const [registrationStatus, setRegistrationStatus] = useState(false);

  return (
      <main>
        <section id="update-academic-records">
          <h2>Update Academic Records</h2>
          <div class="academic-record-form">
            <form>
              <div class="input-container">
                <label for="institutionName">Institution Name:</label>
                <input
                  id="institutionName"
                  name="institutionName"
                  required=""
                  type="text"
                />
              </div>
              <div class="input-container">
                <label for="education">Education:</label>
                <input
                  id="education"
                  name="education"
                  required=""
                  type="text"
                />
              </div>
              <div class="input-container">
                <label for="researchField">Research Field:</label>
                <input
                  id="researchField"
                  name="researchField"
                  required=""
                  type="text"
                />
              </div>
              <div class="input-container">
                <label for="experience">Experience:</label>
                <input
                  id="experience"
                  name="experience"
                  required=""
                  type="text"
                />
              </div>
              <button type="submit">Update Records</button>
            </form>
          </div>
        </section>
      </main>
  );
};

export default UpdateAcademicRec;
