import React, {useState} from "react";
import "../CSS/candidate_dashboard.css";

const Candidate_dashboard = () => {
  return (
      <main>
        <section id="dashboard">
          <h2>Welcome, [Candidate Name]</h2>
          <div class="dashboard-options">
            <div class="option">
              <a href="manageProfile">
                <i class="fa-solid fa-user-circle"></i>
                <p>Create and Manage Profile</p>
              </a>
            </div>
            <div class="option">
              <a href="uploadFiles">
                <i class="fa-solid fa-file-alt"></i>
                <p>Upload Resume</p>
              </a>
            </div>
            <div class="option">
              <a href="updateAcademicRec">
                <i class="fa-solid fa-graduation-cap"></i>
                <p>Upload Academic Records</p>
              </a>
            </div>
            <div class="option">
              <a href="candidatePreferences">
                <i class="fa-solid fa-cog"></i>
                <p>Preferences (Applied Positions/Locations)</p>
              </a>
            </div>
          </div>
        </section>
      </main>
  );
};
export default Candidate_dashboard;
