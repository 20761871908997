import React, { useState, useEffect } from "react";
import List from "../components/List";

let applicantionsReceived = [
  {
    "Candidate ID": "CAND001",
    "Candidate Name": "John Doe",
    "Job ID": "123",
    Position: "Physicist",
    Location: "Las Vegas",
    Status: "Applied",
    "Leave Feedback": "",
  },
  {
    "Candidate ID": "CAND002",
    "Candidate Name": "John Doe",
    "Job ID": "123",
    Position: "Physicist",
    Location: "Las Vegas",
    Status: "Applied",
    "Leave Feedback": "",
  },
];

const StatusOfApplicationAca = () => {

  const [applicantionsReceivedTableHeader, setapplicantionsReceivedTableHeader] = useState([]);
  const [applicantionsReceivedTableData, setapplicantionsReceivedTableData] = useState([]);

  useEffect(() => {
    getapplicantionsReceivedData();
  }, []);

  function getapplicantionsReceivedData() {
    setapplicantionsReceivedTableHeader(Object.keys(applicantionsReceived[0]));
    setapplicantionsReceivedTableData(applicantionsReceived);
  }


  return (
    <main>
      <section class="white-box applied-jobs">
        <h2>Applications Received</h2>
        <List
          tableHeader={applicantionsReceivedTableHeader}
          tableData={applicantionsReceivedTableData}
          updateData={getapplicantionsReceivedData}
        />
      </section>
    </main>
  );
};

export default StatusOfApplicationAca;
