import React from "react";
// import "../CSS/manageProfileRec.css";

const manageProfileRec = () => {
  return (
    <>
      <main>
        <section id="create-manage-profile">
          <h2>Create and Manage Profile</h2>
          <div class="manage-profile-recruiter-container">
            <details class="manage-profile-recruiter-section">
              <summary class="manage-profile-recruiter-section-title">Profile Info</summary>
              <div class="manage-profile-recruiter-section-content">
                <div class="manage-profile-recruiter-section">
                  <div class="input-container">
                    <label for="firstName">First Name:</label>
                    <input
                      id="firstName"
                      name="firstName"
                      required=""
                      type="text"
                    />
                  </div>
                  <div class="input-container">
                    <label for="lastName">Last Name:</label>
                    <input
                      id="lastName"
                      name="lastName"
                      required=""
                      type="text"
                    />
                  </div>
                  <div class="input-container">
                    <label for="phone">Phone Number:</label>
                    <input id="phone" name="phone" required="" type="tel" />
                  </div>
                  <div class="input-container">
                    <label for="address">Address:</label>
                    <input
                      id="address"
                      name="address"
                      required=""
                      type="text"
                    />
                  </div>
                  <label>Gender:</label>
                  <div class="radio-group">
                    <label>
                      <input name="gender" type="radio" value="male" /> Male
                    </label>
                    <label>
                      <input name="gender" type="radio" value="female" /> Female
                    </label>
                    <label>
                      <input name="gender" type="radio" value="other" /> Other
                    </label>
                  </div>
                  <div class="input-container">
                    <label for="dob">Date of Birth:</label>
                    <input id="dob" name="dob" required="" type="date" />
                  </div>
                  <div class="input-container">
                    <label for="email">Email:</label>
                    <input id="email" name="email" required="" type="email" />
                  </div>
                  <div class="input-container">
                    <label for="password">Password:</label>
                    <input
                      id="password"
                      name="password"
                      required=""
                      type="password"
                    />
                  </div>
                  <div class="input-container">
                    <label for="confirmPassword">Confirm Password:</label>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      required=""
                      type="password"
                    />
                  </div>
                  <div class="input-container">
                    <label for="profilePicture">Profile Picture:</label>
                    <input
                      accept="image/*"
                      id="profilePicture"
                      name="profilePicture"
                      type="file"
                    />
                  </div>
                </div>
              </div>
            </details>
          </div>
          <div class="manage-profile-recruiter-container">
            <details class="manage-profile-recruiter-section">
              <summary class="manage-profile-recruiter-section-title">Experience</summary>
              <div class="manage-profile-recruiter-section-content">
                <div class="manage-profile-recruiter-section">
                  <div class="input-container">
                    <label for="agencyName">Agency Name:</label>
                    <input
                      id="agencyName"
                      name="agencyName"
                      required=""
                      type="text"
                    />
                  </div>
                  <div class="input-container">
                    <label for="agencyCode">Agency Code:</label>
                    <input
                      id="agencyCode"
                      name="agencyCode"
                      required=""
                      type="text"
                    />
                  </div>
                  <div class="input-container">
                    <label for="agencyAddress">Agency Address:</label>
                    <input
                      id="agencyAddress"
                      name="agencyAddress"
                      required=""
                      type="text"
                    />
                  </div>
                  <div class="input-container">
                    <label for="position">Position:</label>
                    <input
                      id="position"
                      name="position"
                      required=""
                      type="text"
                    />
                  </div>
                </div>
                <button class="submit-button" type="submit">
                  Submit
                </button>
              </div>
            </details>
          </div>
        </section>
      </main>
    </>
  );
};

export default manageProfileRec;
