import React from "react";
import "../CSS/forgotP.css";

const ForgotPassword = () => {
  return (
     <main>
        <section id="forgot-password">
          <div class="oops-logo">
            <img
              alt="OOPS Logo"
              src="../images/forgorP.png"
            />
          </div>
          <br />
          <div class="forgot-container">
            {/* <!-- <h2>Forgot Password</h2> --> */}
            <p>
              Enter your email address and we will send you a link to reset your
              password.
            </p>
            <form>
              <div class="forgot-input-container">
                <input
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  required=""
                  type="email"
                />
              </div>
              <button class="submit-btn" type="submit">
                Submit
              </button>
            </form>
            <a
              class="back-btn"
              href="signin"
            >
              Back to Login
            </a>
          </div>
        </section>
      </main>
  );
};

export default ForgotPassword;
