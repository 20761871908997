import React from "react";
import "../CSS/manageProfile.css";

const manageProfile = () => {
    return (
      <main>
          <section id="manageProfile-create-manage-profile">
            <h2>Create and Manage Profile</h2>
            <div class="manageProfile-container">
              <details class="manageProfile-section">
                <summary class="manageProfile-section-title">Basic Info</summary>
                <div class="manageProfile-section-content">
                  <label for="first-name">First Name</label>
                  <input
                    id="first-name"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <label for="last-name">Last Name</label>
                  <input
                    id="last-name"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <label for="phone">Phone Number</label>
                  <input
                    id="phone"
                    pattern="[0-9]{10}"
                    placeholder="e.g., 1234567890"
                    title="Please enter a 10-digit phone number"
                    type="tel"
                  />
                  <label for="address">Address</label>
                  <input id="address" required="" type="text" />
                  <label for="email">Email Address</label>
                  <input
                    id="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    required=""
                    title="Please enter a valid email address"
                    type="email"
                  />
                  <label for="password">Change Password</label>
                  <input
                    id="password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&amp;]{8,}$"
                    required=""
                    title="Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number"
                    type="password"
                  />
                  <label for="profile-picture">Profile Picture:</label>
                  <input
                    accept=".pdf,.doc,.docx"
                    id="profile-picture"
                    name="profile-picture"
                    type="file"
                  />
                  <button type="submit">Update Profile Picture</button>
                  <br />
                  <button type="submit">Save Basic Info</button>
                </div>
              </details>
            </div>
            <div class="manageProfile-container">
              <details class="manageProfile-section">
                <summary class="manageProfile-section-title">Educational Info</summary>
                <div class="manageProfile-section-content">
                  <label for="institution-name">Institution Name</label>
                  <input
                    id="institution-name"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <label for="education">Education</label>
                  <input
                    id="education"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <label for="research">Area of research</label>
                  <input
                    id="research"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <label for="experience">Experience</label>
                  <input
                    id="experience"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <button type="submit">Save Educational Info</button>
                </div>
              </details>
            </div>
            <div class="manageProfile-container">
              <details class="manageProfile-section">
                <summary class="manageProfile-section-title">Demographic Info</summary>
                <div class="manageProfile-section-content">
                  <label for="Race">Race</label>
                  <input
                    id="Race"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <label for="ethnicity">Ethnicity</label>
                  <input
                    id="ethnicity"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <label for="Marital Status">Marital Status</label>
                  <input
                    id="Marital Status"
                    pattern="[A-Za-z]+"
                    required=""
                    title="Please enter letters only"
                    type="text"
                  />
                  <label for="gender">Gender</label>
                  <select id="gender" required="">
                    <option disabled="" selected="" value="">
                      Select your gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <br />
                  <br />
                  <button type="submit">Save Demographic Info</button>
                </div>
              </details>
            </div>
            <label for="visibility">Visibility:</label>
            <input id="visibility" name="visibility" type="checkbox" />
            <span>Make profile visible to others</span>
          </section>
        </main>
    );
};
export default manageProfile;
