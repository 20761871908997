import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import About from "./pages/About";
import Home from "./pages/Home";
import Chat from "./pages/chat";
import ContactUS from "./pages/ContactUs";
import Services from "./pages/Services";
import Singin from "./pages/Signin";
import Signup_Candidate from "./pages/SignUp_Candidate";
import Signup_Academia from "./pages/SignUP_Academia";
import SignUp_DEI from "./pages/SignUP_DEI";
import Signup_Recruiter from "./pages/SignUP_Recruiter";
import Academia2 from "./pages/academia2";
import Candidate_dashboard from "./pages/candidate_dashboard";
import ManageProfileAca from "./pages/manageProfileAca";
import ManageProfile from "./pages/manageProfile";
import ManageProfileDEI from "./pages/manageProfileDEI";
import ManageProfileRec from "./pages/manageProfileRec";
import AboutAcademiaInfo from "./pages/aboutAcademiaInfo";
import ViewFaculty from "./pages/viewFaculty";
import NumberOfJP from "./pages/Number_of_JP";
import StatusOfApplicationAca from "./pages/StatusOfApplicationAca";
import ViewCandidateProfiles from "./pages/ViewCandidateProfiles";
import UpdateJobPreferences from "./pages/updateJobPreferences";
import AddNewJob from "./pages/AddNewJob";
import BookmarkedProfiles from "./pages/BookmarkedProfiles";
import UploadFiles from "./pages/uploadFiles";
import UpdateAcademicRec from "./pages/updateAcademicRec";
import CandidatePreferences from "./pages/candidatePreferences";
import UpdateDEIInfo from "./pages/updateDEIInfo";
import RecruiterDashboard from "./pages/recruiterDashboard";
import UpdateAgency from "./pages/updateAgency";
import DEI_dashboard from "./pages/DEI_dashboard";
import ForgotPassword from "./pages/ForgotPassword";


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="contactus" element={<ContactUS />} />
                    <Route path="services" element={<Services />} />
                    <Route path="signin" element={<Singin />} />
                    <Route path="signup_candidate" element={<Signup_Candidate />} />
                    <Route path="signup_academia" element={<Signup_Academia />} />
                    <Route path="signup_dei" element={<SignUp_DEI />} />
                    <Route path="signup_recruiter" element={<Signup_Recruiter />} />
                    <Route path="academia2" element={<Academia2 />} />
                    <Route path="candidate_dashboard" element={<Candidate_dashboard/>} />
                    <Route path="dei_dashboard" element={<DEI_dashboard/>} />
                    <Route path="forgotPassword" element={<ForgotPassword/>} />
                    <Route path="aboutAcademiaInfo" element={<AboutAcademiaInfo />} />
                    <Route path="manageProfileAca" element={<ManageProfileAca />} />
                    <Route path="manageProfile" element={<ManageProfile />} />
                    <Route path="manageProfileDEI" element={<ManageProfileDEI />} />
                    <Route path="manageProfileRec" element={<ManageProfileRec />} />
                    <Route path="viewFaculty" element={<ViewFaculty />} />
                    <Route path="numberOfJP" element={<NumberOfJP />} />
                    <Route path="statusOfApplicationAca" element={<StatusOfApplicationAca />} />
                    <Route path="viewCandidateProfiles" element={<ViewCandidateProfiles />} />
                    <Route path="updateJobPreferences" element={<UpdateJobPreferences />} />
                    <Route path="addNewJob" element={<AddNewJob />} />
                    <Route path="bookmarkedProfiles" element={<BookmarkedProfiles />} />
                    <Route path="uploadFiles" element={<UploadFiles />} />
                    <Route path="updateAcademicRec" element={<UpdateAcademicRec />} />
                    <Route path="candidatePreferences" element={<CandidatePreferences />} />
                    <Route path="updateDEIInfo" element={<UpdateDEIInfo />} />
                    <Route path="recruiterDashboard" element={<RecruiterDashboard />} />
                    <Route path="updateAgency" element={<UpdateAgency />} />
                    <Route path="chat" element={<Chat />} />

                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
