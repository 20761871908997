import React from "react";
import "../CSS/manageProfileDEI.css";

const manageProfileDEI = () => {
  return (
    <>
      <main>
        <section id="create-manage-profile">
          <h2>Create and Manage Profile</h2>
          <div class="manage-profile-dei-container">
            <details class="manage-profile-dei-section">
              <summary class="manage-profile-dei-section-title">Basic Info</summary>
              <div class="manage-profile-dei-section-content">
                <label for="first-name">First Name</label>
                <input
                  id="first-name"
                  pattern="[A-Za-z]+"
                  required=""
                  title="Please enter letters only"
                  type="text"
                />
                <label for="last-name">Last Name</label>
                <input
                  id="last-name"
                  pattern="[A-Za-z]+"
                  required=""
                  title="Please enter letters only"
                  type="text"
                />
                <label for="phone">Phone Number</label>
                <input
                  id="phone"
                  pattern="[0-9]{10}"
                  placeholder="e.g., 1234567890"
                  title="Please enter a 10-digit phone number"
                  type="tel"
                />
                <label>Gender:</label>
                <div class="radio-group">
                  <label>
                    <input name="gender" type="radio" value="male" /> Male
                  </label>
                  <label>
                    <input name="gender" type="radio" value="female" /> Female
                  </label>
                  <label>
                    <input name="gender" type="radio" value="other" /> Other
                  </label>
                </div>
                <label for="dateOfBirth">Date of Birth</label>
                <input
                  id="dateOfBirth"
                  pattern="\d{4}-\d{2}-\d{2}"
                  title="Please enter a valid date in the format YYYY-MM-DD"
                  type="text"
                />
                <label for="address">Address</label>
                <input id="address" required="" type="text" />
                <label for="email">Email Address</label>
                <input
                  id="email"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                  required=""
                  title="Please enter a valid email address"
                  type="email"
                />
                <label for="password">Change Password</label>
                <input
                  id="password"
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&amp;]{8,}$"
                  required=""
                  title="Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number"
                  type="password"
                />
                <label for="profile-picture">Profile Picture:</label>
                <input
                  accept=".pdf,.doc,.docx"
                  id="profile-picture"
                  name="profile-picture"
                  type="file"
                />
                <button type="submit">Update Profile Picture</button>
                <br />
                <button type="submit">Save Basic Info</button>
              </div>
            </details>
          </div>
          <div class="manage-profile-dei-container">
            <details class="manage-profile-dei-section">
              <summary class="manage-profile-dei-section-title">Experience</summary>
              <div class="manage-profile-dei-section-content">
                <label for="dei-name">DEI Name</label>
                <input
                  id="dei-name"
                  pattern="[A-Za-z]+"
                  required=""
                  title="Please enter letters only"
                  type="text"
                />
                <label for="dei">DEI Code</label>
                <input
                  id="dei"
                  pattern="[A-Za-z0-9]+"
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="deiAddress">DEI Address:</label>
                <input
                  id="deiAddress"
                  name="deiAddress"
                  pattern="[A-Za-z0-9 ]+"
                  required=""
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="position">Position:</label>
                <input
                  id="position"
                  name="position"
                  pattern="[A-Za-z0-9 ]+"
                  required=""
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="areaOfResearch">Area of Research:</label>
                <input
                  id="areaOfResearch"
                  name="areaOfResearch"
                  pattern="[A-Za-z0-9 ]+"
                  required=""
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <button type="submit">Save DEI Info</button>
              </div>
            </details>
          </div>
        </section>
      </main>
    </>
  );
};

export default manageProfileDEI;
