import React, {useState} from "react";
import "../CSS/manageProfileRec.css";

const UpdateAgency = () => {
  const [formData, setFormData] = useState({
    clientAddress: "",
    clientId: "",
    AgencyName: "",
    AgencyAddress: "",
  });

  const [submitStatus, setsubmitStatus] = useState("");

  const [registrationStatus, setRegistrationStatus] = useState(false);

  return (
      <main>
        <section id="Update Agency Details">
          <h2>Update Agency Details</h2>
          <div class="manage-profile-recruiter-container">
            <details class="manage-profile-recruiter-section">
              <summary class="manage-profile-recruiter-section-title">Agency Info</summary>
              <div class="manage-profile-recruiter-section-content">
                <label for="Agency-name">Agency Name</label>
                <input
                  id="Agency-name"
                  pattern="[A-Za-z]+"
                  required=""
                  title="Please enter letters only"
                  type="text"
                />
                <label for="Agency">Agency Code</label>
                <input
                  id="Agency"
                  pattern="[A-Za-z0-9]+"
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="AgencyAddress">Agency Address:</label>
                <input
                  id="AgencyAddress"
                  name="AgencyAddress"
                  pattern="[A-Za-z0-9 ]+"
                  required=""
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="areaOfResearch">Area of Research:</label>
                <input
                  id="areaOfResearch"
                  name="areaOfResearch"
                  pattern="[A-Za-z0-9 ]+"
                  required=""
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <button type="submit">Save Agency Info</button>
              </div>
            </details>
          </div>
        </section>
        <section id="Update Agency Details">
          <h2>Update Client Details</h2>
          <div class="manage-profile-recruiter-container">
            <details class="manage-profile-recruiter-section">
              <summary class="manage-profile-recruiter-section-title">Clients Info</summary>
              <div class="manage-profile-recruiter-section-content">
                <label for="client-name">Client Name</label>
                <input
                  id="client-name"
                  pattern="[A-Za-z]+"
                  required=""
                  title="Please enter letters only"
                  type="text"
                />
                <label for="client">Client Code</label>
                <input
                  id="client"
                  pattern="[A-Za-z0-9]+"
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="clientAddress">Client Address:</label>
                <input
                  id="clientAddress"
                  name="clientAddress"
                  pattern="[A-Za-z0-9 ]+"
                  required=""
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <label for="areaOfResearch">Client ID:</label>
                <input
                  id="areaOfResearch"
                  name="areaOfResearch"
                  pattern="[A-Za-z0-9 ]+"
                  required=""
                  title="Please enter letters and numbers only"
                  type="text"
                />
                <button type="submit">Save client Info</button>
              </div>
            </details>
          </div>
        </section>
      </main>
  );
};

export default UpdateAgency;
