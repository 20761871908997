import React from "react";
// import "../CSS/updateJobPreferences.css";

const AdminDashboard = () => {
    return (
      <main>
        <div>
          <h2 style={{ textAlign: "center" }}>
            Modify Job Postings & Update Preferences
          </h2>
          <div class="main-content">
            <div class="dashboard-section">
              <div class="data-table">
                <div class="search-container">
                  <label for="search-input">Search:</label>
                  <input
                    type="text"
                    id="search-input"
                    placeholder="Enter search keyword"
                  />
                </div>
                <table class="job-postings-table">
                  <thead>
                    <tr>
                      <th>JOB ID</th>
                      <th>Job Name</th>
                      <th>Location</th>
                      <th>Salary</th>
                      <th>Experience</th>
                      <th>Race</th>
                      <th>Gender</th>
                      <th>Preferred Skills</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>123</td>
                      <td>Data Analyst</td>
                      <td>New York</td>
                      <td>$60,000</td>
                      <td>2 years</td>
                      <td>Asian</td>
                      <td>Male</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>456</td>
                      <td>Software Engineer</td>
                      <td>San Francisco</td>
                      <td>$80,000</td>
                      <td>3 years</td>
                      <td>African American</td>
                      <td>Female</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>789</td>
                      <td>Marketing Specialist</td>
                      <td>Chicago</td>
                      <td>$65,000</td>
                      <td>2 years</td>
                      <td>Hispanic</td>
                      <td>Male</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>987</td>
                      <td>Financial Analyst</td>
                      <td>Los Angeles</td>
                      <td>$70,000</td>
                      <td>4 years</td>
                      <td>Caucasian</td>
                      <td>Non-Binary</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <button class="filter-button">Filter</button>
              </div>
              <a href="submit.html">
                <button>Save Preferences</button>
              </a>
            </div>
          </div>
        </div>
      </main>
    );
  };
export default AdminDashboard;
