import React from "react";
import "../CSS/academiaDashboard.css";

const AddNewJob = () => {
  return (
    <>
      <main>
        <div>
            <h2 style={{textAlign: "center"}}>Add a New Job Posting</h2>
            <div class="main-content">
                <div class="box">
                  <div class="data-table">
                    <div class="search-container">
                      <label for="search-input">Search:</label>
                      <input type="text" id="search-input" placeholder="Enter search keyword"/>
                    </div>
                    <table class="job-postings-table">
                        <thead>
                            <tr>
                                <th>JOB ID</th>
                                <th>Job Name</th>
                                <th>Location</th>
                                <th>Salary</th>
                                <th>Experience</th>
                                <th>Race</th>
                                <th>Gender</th>
                                <th>Preferred Skills</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="text" placeholder="Job ID"/></td>
                                <td><input type="text" placeholder="Job Name"/></td>
                                <td><input type="text" placeholder="Location"/></td>
                                <td><input type="text" placeholder="Salary"/></td>
                                <td><input type="text" placeholder="Experience"/></td>
                                <td><input type="text" placeholder="Race"/></td>
                                <td><input type="text" placeholder="Gender"/></td>
                                <td><input type="text" placeholder="Preferred Skills"/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
          </div>
        </div>
        <a href="submit.html">
            <button>Save Job</button>
        </a>
      </div>
    </main>
    </>
  );
};

export default AddNewJob;
