import React from "react";
import "../CSS/academiaDashboard.css";

const BookmarkedProfiles = () => {

    return (
      <main>
      <div>
          <h2 style={{textAlign: "center"}}>Candidate Profiles</h2>
          <div class="main-content">
              <div class="dashboard-section">
                <div class="data-table">
                  <div class="search-container">
                    <label for="search-input">Search:</label>
                    <input type="text" id="search-input" placeholder="Enter search keyword"/>
                  </div>
              <table>
                  <thead>
                      <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Profile_Pic</th>
                          <th>Area of Research</th>
                          <th>Experience</th>
                          <th>Gender</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td><a href="#">CAND001</a></td>
                          <td>John Doe</td>
                          <td class="profile-photo-column"><img src="profile1.jpg" alt="Profile Photo" width="50"/></td>
                          <td class="education-column">Machine Learning</td>
                          <td class="experience-column">3 years</td>
                          <td class="race-column">Male</td>
                      </tr>
                      <tr>
                          <td><a href="#">CAND002</a></td>
                          <td>Jane Smith</td>
                          <td class="profile-photo-column"><img src="profile2.jpg" alt="Profile Photo" width="50"/></td>
                          <td class="education-column">Data Science</td>
                          <td class="experience-column">1 year</td>
                          <td class="race-column">Female</td>
                      </tr>
                      <tr>
                          <td><a href="#">CAND003</a></td>
                          <td>Michael Johnson</td>
                          <td class="profile-photo-column"><img src="profile3.jpg" alt="Profile Photo" width="50"/></td>
                          <td class="education-column">Artificial Intelligence</td>
                          <td class="experience-column">5 years</td>
                          <td class="race-column">Male</td>
                      </tr>
                  </tbody>
              </table>
              <button class="filter-button">Filter</button>
          </div>
        </div>
      </div>
    </div>
  </main>
    );
};
export default BookmarkedProfiles;
